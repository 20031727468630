<template>
  <div class="nav-info">
    <div class="nav-search">
      <div class="nav">
        <router-link to="/" replace class="item" :class="{'active':PitchOn==='首页'}">
          <span>首页</span>
        </router-link>
        <router-link to="/article" replace class="item" :class="{'active':PitchOn==='文章'}">
          <span>文章</span>
        </router-link>
        <router-link to="/works" replace class="item" :class="{'active':PitchOn==='作品'}">
          <span>作品</span>
        </router-link>
        <router-link to="/OpenSource" replace class="item" :class="{'active':PitchOn==='开源项目'}">
          <span>开源项目</span>
        </router-link>
        <router-link to="/LeaveMessage" replace class="item" :class="{'active':PitchOn==='留言'}">
          <span>留言</span>
        </router-link>
        <router-link to="/AboutMe" replace class="item" :class="{'active':PitchOn==='关于我'}">
          <span>关于我</span>
        </router-link>
      </div>
      <div class="search">
        <div class="search-input" :class="{'show':isSearch}">
          <input type="text" placeholder="请输入搜索内容" v-model="keyword" @keyup.enter="onSearch" v-show="isSearch" />
        </div>
        <div class="icon" @click="isSearch = !isSearch">
          <span class="iconfont icon-fadajing"></span>
        </div>
      </div>
    </div>
    <el-backtop />
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import {useRouter} from "vue-router";
export default defineComponent({
  name: "NavBar",
  props: {
    PitchOn: {
      type: String,
      default: '首页'
    }
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      isSearch: false,
      keyword: '',

      onSearch(){
        router.push({path: '/SearchResult',query: {keyword: state.keyword}})
      }
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>

<style scoped lang="scss">
@import "NavBar.scss";
</style>
