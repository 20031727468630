import axios from '../api'

/**
 * get请求封装
 * @param {string} url
 * @param {Object} params
 * @returns {AxiosPromise}
 */
// function get(url, params) {
//   return axios({
//     header: {
//       'Access-Control-Allow-Origin': '*',
//     },
//     url: url,
//     method: 'GET',
//     params: params,
//   })
// }

/**
 * post请求封装
 * @param {string} url
 * @param {Object} params
 * @returns {AxiosPromise}
 */
function post(url, params) {
  return axios({
    header: {
      'Access-Control-Allow-Origin': '*',
    },
    url: url,
    method: 'POST',
    data: params
  })
}

/**
 * 文件上传接口封装
 * @param {string} url
 * @param {Object} params
 * @returns {AxiosPromise}
 */
// function postUpLoad(url, params) {
//   let param = new FormData();
//   if(params.video){     // 视频
//     param.append('video', params.video);
//   }else if(params.image){  // 头像
//     param.append('image', params.image);
//   } else {    // 图片
//     param.append('file', params.file);
//   }
//   param.append('type', params.type||'');
//   param.append('member_id', params.member_id||'');
//   param.append('daid', params.daid||'');
//   param.append('order_no', params.order_no||'');
//   return axios({
//     header: {
//       'Access-Control-Allow-Origin': '*',
//     },
//     headers: {
//       'Content-Type': 'multipart/form-data;',
//       'Accept': '*/*',
//     },
//     url: url,
//     method: 'POST',
//     data: param
//   })
// }

// 首页
export const homeData = params => {
  return post('/api/v1/home', params)
};

// 右边数据
export const RightData = params => {
  return post('/api/v1/RightData', params)
};

// 文章列表
export const ArticleList = params => {
  return post('/api/v1/article/ArticleList', params)
};

// 文章筛选列表
export const ArticleFiltrate = params => {
  return post('/api/v1/article/ArticleFiltrate', params)
};

// 文章详情
export const ArticleDetails = params => {
  return post('/api/v1/article/ArticleDetails', params)
};

// 文章评论列表
export const ArticleCommentList = params => {
  return post('/api/v1/article/ArticleCommentList', params)
};

// 文章评论
export const ArticleCommentPut = params => {
  return post('/api/v1/article/ArticleCommentPut', params)
};

// 留言列表
export const LeaveList = params => {
  return post('/api/v1/leave/LeaveList', params)
};

// 留言发布
export const LeavePut = params => {
  return post('/api/v1/leave/LeavePut', params)
};

// 关于我
export const AboutMe = params => {
  return post('/api/v1/setting/AboutMe', params)
};
