import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  // baseURL: 'http://admin-blogs.996ico.cn',
  timeout: 50000
});
/**
 * 请求前
 */
service.interceptors.request.use(config => {
  // let data = config.data;
  // if(!data || !data.loading){
  // }
  return config
}, error => {
  return Promise.reject(error)
});

/**
 * 请求后
 */
service.interceptors.response.use((resp) => {
  if(resp.data.code !== 200){
    return Promise.reject(resp.data.msg);
  }
  return Promise.resolve(resp.data);
},  (err) => {
  return Promise.reject(err)
});

export default service
